<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import {monitorMethods, userMethods} from '@/state/helpers'
import {getApi} from "@/api";
import {v4 as uuidv4} from 'uuid'

export default {
  name: 'app',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    }
  },
  methods: {
    ...getApi(),
    ...userMethods,
    ...monitorMethods,
  },
  mounted() {
    this.uuid = uuidv4();
    const user_id = Number(sessionStorage.getItem('userId')), token = sessionStorage.getItem('accessToken'),
        user_type = sessionStorage.getItem('userType')
    if (user_id && token) {
      this.setToken({
        user: {
          user_id,
          token,
          user_type,
        }
      })

      this.getUser(user_id, token, this.uuid)
          .then(response => response.json())
          .then(u => {
            if (!u.status || !u.user) {
              sessionStorage.clear(); // Clear all session storage data
              window.location.href = '/login'; // Redirect to the login page
              return; // Stop further execution
            }
            if (u.user?.image) u.user.image += '?unity=true';
            sessionStorage.setItem('user', JSON.stringify(u.user));
            this.setUser({user: u.user});
            this.setMessages({messages: u.messages});
            this.setFeatures({features: u.features});
          })
          .catch(error => {
            console.error("Error fetching user:", error);
            // Optional: clear sessionStorage and redirect in case of error
            sessionStorage.clear();
            window.location.href = '/login';
          });

    }
  }
}
</script>
