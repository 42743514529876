export const state = {
    patients: [], guests: [], messages: [], users: [], features: {}
}

export const getters = {}

export const mutations = {
    SET_PATIENTS(state, patients) {
        state.patients = patients
    }, SET_USERS(state, users) {
        state.users = users
    }, SET_MESSAGES(state, messages) {
        state.messages = messages.map(m => {
            if (!m.image.includes('?unity=true')) m.image += '?unity=true'
            if (m.content && m.content.url && !m.content.url.includes('?unity=true')) m.content.url += '?unity=true'
            return m
        })
    }, SET_FEATURES(state, features) {
        state.features = features
    }, SET_GUESTS(state, guests) {
        state.guests = guests
    }, RESET_PATIENTS(state) {
        state.patients = []
    }, RESET_USERS(state) {
        state.users = []
    }, REMOVE_USER(state, userId) {
        state.users = state.users.filter(user => user.id !== userId);
    }, RESET_MESSAGES(state) {
        state.messages = []
    }, RESET_FEATURES(state) {
        state.features = {}
    }, RESET_GUESTS(state) {
        state.guests = []
    }, UPDATE_PATIENT_LAST_SESSION(state, {patientId, lastSession}) {
        const patient = state.patients.find(p => p.id === patientId);
        if (patient) {
            patient.lastSession = lastSession;
            if (patient.lastSession) {
                patient.isActive = Date.now - lastSession.T < 60 * 60 * 1000;
                patient.lastSessionDate = new Date(patient.lastSession.T).toISOString().split('T')[0];
            } else {
                patient.isActive = false;
                patient.lastSessionDate = "N/A";
            }
        }
    }
}

export const actions = {
    resetPatients({commit}) {
        commit('RESET_PATIENTS')
    }, resetUsers({commit}) {
        commit('RESET_USERS')
    }, resetMessages({commit}) {
        commit('RESET_MESSAGES')
    }, resetFeatures({commit}) {
        commit('RESET_FEATURES')
    }, resetGuests({commit}) {
        commit('RESET_GUESTS')
    },

    setPatients({commit}, {patients}) {
        patients = patients.map(p => {
            p.name = p.first_name + ' ' + p.last_name
            if (p.image) {
                p.image += '?unity=true'
            }
            return p
        })
        commit('SET_PATIENTS', patients)
    },

    setUsers({commit}, {users}) {
        users = users.map(u => {
            u.name = u.first_name + ' ' + u.last_name
            if (u.image) {
                u.image += '?unity=true'
            }
            return u
        })
        commit('SET_USERS', users)
    },

    removeUser({commit}, {userId}) {
        // Commit the mutation to remove the user with the given userId
        commit('REMOVE_USER', userId);
    },

    setMessages({commit}, {messages}) {
        messages = messages.map(m => {
            return m
        })
        commit('SET_MESSAGES', messages)
    },

    setFeatures({commit}, {features}) {
        commit('SET_FEATURES', features)
    },

    setGuests({commit}, {guests}) {
        commit('SET_GUESTS', guests)
    },

    setPatientLastSession({commit}, {patientId, lastSession}) {
        commit('UPDATE_PATIENT_LAST_SESSION', {patientId, lastSession})
    },
}
